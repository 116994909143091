import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Koros, Link } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/koros/code",
  "title": "Koros - Code"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconCheckCircleFill = makeShortcode("IconCheckCircleFill");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "code-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Code examples`}<a parentName="h3" {...{
        "href": "#code-examples",
        "aria-label": "code examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<>
<Koros type="basic" style={{marginBottom: 'var(--spacing-xs'}} aria-label="Basic koro example"/>
<Koros type="beat" style={{marginBottom: 'var(--spacing-xs'}} />
<Koros type="pulse" style={{marginBottom: 'var(--spacing-xs'}} />
<Koros type="wave" style={{marginBottom: 'var(--spacing-xs'}} />
<Koros type="storm" style={{marginBottom: 'var(--spacing-xs'}} />
<Koros type="calm"/>
</>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div>
<div class="hds-koros" style="margin-bottom: var(--spacing-xs)">
  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="100%" height="85">
    <defs>
      <pattern id="korosBasic" x="0" y="0" width="106" height="85" patternUnits="userSpaceOnUse">
        <path
          transform="scale(5.3)"
          d="M0,800h20V0c-4.9,0-5,2.6-9.9,2.6S5,0,0,0V800z"
        />
      </pattern>
    </defs>
    <rect fill="url(#korosBasic)" width="100%" height="85" />
  </svg>
</div>
<div class="hds-koros" style="margin-bottom: var(--spacing-xs)">
  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="100%" height="85">
    <defs>
      <pattern id="korosBeat" x="0" y="0" width="106" height="85" patternUnits="userSpaceOnUse">
      <path
        transform="scale(5.3)"
        d="M20,800H0V0c2.8,0,3.5,2.3,3.5,2.3l2.8,8.4c0.6,1.5,1.9,2.5,3.6,2.5c2.8,0,3.6-2.5,3.6-2.5s2.8-8.1,2.8-8.2 C17,1,18.3,0,20,0V800z"
      />
      </pattern>
    </defs>
    <rect fill="url(#korosBeat)" width="100%" height="85" />
  </svg>
</div>
<div class="hds-koros" style="margin-bottom: var(--spacing-xs)">
  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="100%" height="85">
    <defs>
      <pattern id="korosPulse" x="0" y="0" width="106" height="85" patternUnits="userSpaceOnUse">
      <path
        transform="scale(5.3)"
        d="M0,800h20V0c-5.1,0-5.1,6.4-10,6.4S4.9,0,0,0V800z"
      />
      </pattern>
    </defs>
    <rect fill="url(#korosPulse)" width="100%" height="85" />
  </svg>
</div>
<div class="hds-koros" style="margin-bottom: var(--spacing-xs)">
  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="100%" height="85">
    <defs>
      <pattern id="korosWave" x="0" y="0" width="106" height="85" patternUnits="userSpaceOnUse">
      <polygon
        transform="scale(5.3)"
        points="0,800 20,800 20,0 9.8,10.1 0,0 "
      />
      </pattern>
    </defs>
    <rect fill="url(#korosWave)" width="100%" height="85" />
  </svg>
</div>
<div class="hds-koros" style="margin-bottom: var(--spacing-xs)">
  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="100%" height="85">
    <defs>
      <pattern id="korosStorm" x="0" y="0" width="106" height="85" patternUnits="userSpaceOnUse">
      <path
        transform="scale(5.3)"
        d="M20,800V0c-2.3,5.5-8.7,8.1-14.3,5.7C3.1,4.7,1.2,2.6,0,0v800H20z"
      />
      </pattern>
    </defs>
    <rect fill="url(#korosStorm)" width="100%" height="85" />
  </svg>
</div>
<div class="hds-koros">
  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="100%" height="85">
    <defs>
      <pattern id="korosCalm" x="0" y="0" width="106" height="85" patternUnits="userSpaceOnUse">
      <path d="M788 0.279785H0V109.739H788V0.279785Z" />
      </pattern>
    </defs>
    <rect fill="url(#korosCalm)" width="100%" height="85" />
  </svg>
</div>
</div>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "dense",
      "style": {
        "position": "relative"
      }
    }}>{`Dense`}<a parentName="h4" {...{
        "href": "#dense",
        "aria-label": "dense permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<>
<Koros dense type="basic" style={{marginBottom: 'var(--spacing-xs'}} />
<Koros dense type="beat" style={{marginBottom: 'var(--spacing-xs'}} />
<Koros dense type="pulse" style={{marginBottom: 'var(--spacing-xs'}} />
<Koros dense type="wave" style={{marginBottom: 'var(--spacing-xs'}} />
<Koros dense type="storm" style={{marginBottom: 'var(--spacing-xs'}} />
<Koros dense type="calm"/>
</>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div>
  <div class="hds-koros" style="margin-bottom: var(--spacing-xs)">
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="100%" height="85">
      <defs>
        <pattern id="korosBasic" x="0" y="0" width="35" height="85" patternUnits="userSpaceOnUse">
          <path
            transform="scale(1.8)"
            d="M0,800h20V0c-4.9,0-5,2.6-9.9,2.6S5,0,0,0V800z"
          />
        </pattern>
      </defs>
      <rect fill="url(#korosBasic)" width="100%" height="85" />
    </svg>
  </div>
  <div class="hds-koros" style="margin-bottom: var(--spacing-xs)">
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="100%" height="85">
      <defs>
        <pattern id="korosBeat" x="0" y="0" width="35" height="85" patternUnits="userSpaceOnUse">
        <path
          transform="scale(1.8)"
          d="M20,800H0V0c2.8,0,3.5,2.3,3.5,2.3l2.8,8.4c0.6,1.5,1.9,2.5,3.6,2.5c2.8,0,3.6-2.5,3.6-2.5s2.8-8.1,2.8-8.2 C17,1,18.3,0,20,0V800z"
        />
        </pattern>
      </defs>
      <rect fill="url(#korosBeat)" width="100%" height="85" />
    </svg>
  </div>
  <div class="hds-koros" style="margin-bottom: var(--spacing-xs)">
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="100%" height="85">
      <defs>
        <pattern id="korosPulse" x="0" y="0" width="35" height="85" patternUnits="userSpaceOnUse">
        <path
          transform="scale(1.8)"
          d="M0,800h20V0c-5.1,0-5.1,6.4-10,6.4S4.9,0,0,0V800z"
        />
        </pattern>
      </defs>
      <rect fill="url(#korosPulse)" width="100%" height="85" />
    </svg>
  </div>
  <div class="hds-koros" style="margin-bottom: var(--spacing-xs)">
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="100%" height="85">
      <defs>
        <pattern id="korosWave" x="0" y="0" width="35" height="85" patternUnits="userSpaceOnUse">
        <polygon
          transform="scale(1.8)"
          points="0,800 20,800 20,0 9.8,10.1 0,0 "
        />
        </pattern>
      </defs>
      <rect fill="url(#korosWave)" width="100%" height="85" />
    </svg>
  </div>
  <div class="hds-koros" style="margin-bottom: var(--spacing-xs)">
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="100%" height="85">
      <defs>
        <pattern id="korosStorm" x="0" y="0" width="35" height="85" patternUnits="userSpaceOnUse">
        <path
          transform="scale(1.8)"
          d="M20,800V0c-2.3,5.5-8.7,8.1-14.3,5.7C3.1,4.7,1.2,2.6,0,0v800H20z"
        />
        </pattern>
      </defs>
      <rect fill="url(#korosStorm)" width="100%" height="85" />
    </svg>
  </div>
  <div class="hds-koros">
  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="100%" height="85">
    <defs>
      <pattern id="korosCalm" x="0" y="0" width="106" height="85" patternUnits="userSpaceOnUse">
      <path d="M788 0.279785H0V109.739H788V0.279785Z" />
      </pattern>
    </defs>
    <rect fill="url(#korosCalm)" width="100%" height="85" />
  </svg>
</div>
</div>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "angled",
      "style": {
        "position": "relative"
      }
    }}>{`Angled`}<a parentName="h3" {...{
        "href": "#angled",
        "aria-label": "angled permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const rootStyle = { '--koros-height': '85px', '--hero-height': '300px', '--hero-width': '100%' };
  return (
    <div
      style={{
        ...rootStyle,
        backgroundColor: 'var(--color-silver-light)',
        height: 'var(--hero-height)',
        maxWidth: '100%',
        overflow: 'hidden',
        position: 'relative',
        width: 'var(--hero-width)',
      }}
    >
      <div
        style={{
          backgroundColor: 'var(--color-coat-of-arms)',
          clipPath: 'polygon(0 0, var(--hero-height) 0, 0 100%, 0% 100%)',
          height: '100%',
        }}
      />
      <Koros
        style={{
          fill: 'var(--color-coat-of-arms)',
          left: 'calc(-1 * var(--koros-height))',
          position: 'absolute',
          top: 'var(--koros-height)',
          transformOrigin: 'center',
          width: 'calc(2 * var(--hero-height))',
        }}
        rotate="135deg"
      />
    </div>
  );
}}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div style="--koros-height:85px; --hero-height:300px; --hero-width:500px; background-color: var(--color-silver-light); height: var(--hero-height); max-width: 100%; overflow: hidden; position: relative; width: var(--hero-width);">
  <div style="background-color: var(--color-coat-of-arms); clip-path: polygon(0 0, var(--hero-height) 0, 0 100%, 0% 100%); height: 100%;"></div>
  <div class="hds-koros hds-koros--135deg hds-custom-koros" style="fill: var(--color-coat-of-arms); left: calc(-1 * var(--koros-height)); position: absolute; top: var(--koros-height); transform-origin: center center; width: calc(2 * var(--hero-height)); transform: rotate(135deg) translateZ(0px);">
   <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="100%" height="85">
     <defs>
       <pattern id="korosBasic135deg" x="0" y="0" width="106" height="85" patternUnits="userSpaceOnUse">
         <path transform="scale(5.3)" d="M0,800h20V0c-4.9,0-5,2.6-9.9,2.6S5,0,0,0V800z" />
       </pattern>
     </defs>
     <rect fill="url(#korosBasic135deg)" width="100%" height="85" />
   </svg>
  </div>
</div>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storybook link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS React`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/docs/components-koros--basic" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/react/src/components/koros" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/core/?path=/story/components-koros--basic" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/core/src/components/koros" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Note! You can find the full list of properties in the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/docs/components-koros--basic" mdxType="Link">{`React Storybook.`}</Link></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dense`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the dense koro variant is used.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`flipHorizontal`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the koro shape will be flipped horizontally.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`rotate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Controls the rotation of the koro shape.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"45deg"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"90deg"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"135deg"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"180deg"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"225deg"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"270deg"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"315deg"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Controls the type (shape) of the koro.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"basic"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"beat"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"pulse"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"storm"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"wave"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"calm"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"basic"`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      